export const defined = (value) => value !== undefined;
export const notNull = (value) => value !== null && value !== undefined;
export const isEmpty = (a) => !a || a.length === 0;
export const notEmpty = (a) => !isEmpty(a);
// like mithril prop but with type safety
export const prop = (initialValue) => {
    let value = initialValue;
    return (v) => {
        if (defined(v))
            value = v;
        return value;
    };
};
export const propWithEffect = (initialValue, effect) => {
    let value = initialValue;
    return (v) => {
        if (defined(v)) {
            value = v;
            effect(v);
        }
        return value;
    };
};
export const withEffect = (prop, effect) => (v) => {
    let returnValue;
    if (defined(v)) {
        returnValue = prop(v);
        effect(v);
    }
    else
        returnValue = prop();
    return returnValue;
};
export const toggle = (initialValue, effect = () => { }) => {
    const prop = propWithEffect(initialValue, effect);
    prop.toggle = () => prop(!prop());
    prop.effect = effect;
    return prop;
};
// Only computes a value once. The computed value must not be undefined.
export const memoize = (compute) => {
    let computed;
    return () => {
        if (computed === undefined)
            computed = compute();
        return computed;
    };
};
export const scrollToInnerSelector = (el, selector, horiz = false) => scrollTo(el, el.querySelector(selector), horiz);
export const scrollTo = (el, target, horiz = false) => {
    if (target)
        horiz
            ? (el.scrollLeft = target.offsetLeft - el.offsetWidth / 2 + target.offsetWidth / 2)
            : (el.scrollTop = target.offsetTop - el.offsetHeight / 2 + target.offsetHeight / 2);
};
export const onClickAway = (f) => (el) => {
    const listen = () => $(document).one('click', e => {
        if (!document.contains(el)) {
            return;
        }
        if (el.contains(e.target)) {
            listen();
        }
        else {
            f();
        }
    });
    setTimeout(listen, 300);
};
export function hyphenToCamel(str) {
    return str.replace(/-([a-z])/g, g => g[1].toUpperCase());
}
export const requestIdleCallback = (f, timeout) => {
    if (window.requestIdleCallback)
        window.requestIdleCallback(f, timeout ? { timeout } : undefined);
    else
        requestAnimationFrame(f);
};
export const escapeHtml = (str) => /[&<>"']/.test(str)
    ? str
        .replace(/&/g, '&amp;')
        .replace(/</g, '&lt;')
        .replace(/>/g, '&gt;')
        .replace(/'/g, '&#39;')
        .replace(/"/g, '&quot;')
    : str;
export function frag(html) {
    const div = document.createElement('div');
    div.innerHTML = html;
    const fragment = document.createDocumentFragment();
    while (div.firstChild)
        fragment.appendChild(div.firstChild);
    return (fragment.childElementCount === 1 ? fragment.firstElementChild : fragment);
}
export function $as(cashOrHtml) {
    return (typeof cashOrHtml === 'string' ? $(cashOrHtml) : cashOrHtml)[0];
}
